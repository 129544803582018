<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>Spelling {{ $store.state.user.locale === 'en_US' ? 'Curriculum' : 'Scheme' }}</h2>
          </div>
          <div v-if="isTrial" class="box-body content">
            <p v-if="$store.state.user && $store.state.user.locale === 'en_US'">
              The Spelling Shed spelling {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme of work' }} gives full coverage of the curriculum for grades K-5 but lists from any Stage can be used with any grade level. Each stage relates to its corresponding year so Stage 1 is for Kindergarten, Stage 2 is for 1st grade etc. The {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is broken down into weekly objectives, each of which has a word list, a learning activity and a practice sheet. All of the spelling lists are available in our system for use within the Spelling Shed web and mobile games.
            </p>
            <p v-else>
              The Spelling Shed spelling {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme of work' }} gives full coverage of the curriculum for Years 1-6. Each stage relates to its corresponding year so stage 1 is for year 1. The {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is broken down into weekly objectives, each of which has a word list, a learning activity and a practice sheet. All of the spelling lists are available in our system for use within the Spelling Shed web and mobile games.
            </p>
            <p><b>N.B. This is only a sample. The full {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is available with any subscription.</b></p>
            <router-link to="/lists" tag="button" class="button is-link">
              View spelling lists
            </router-link>
            <p>Our scheme PowerPoints use the font "OpenDyslexicAlta". Download the font <a href="https://www.opendyslexic.org/">HERE</a>.</p>
            <section class="section">
              <table class="table is-striped is-bordered">
                <thead>
                  <tr>
                    <th>Stage</th><th>Print PDF</th><th>Powerpoint</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="locale === 'en_US'">
                    <td><b>Sample</b></td><td><a :href="'https://files.edshed.com/scheme/en_US/Spelling-Shed-Curriculum-Sample.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Sample</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/en_US/Spelling-Shed-Curriculum-Sample.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Sample</a></td>
                  </tr>
                  <tr v-else>
                    <td><b>Sample</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Sample.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Sample</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Sample.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Sample</a></td>
                  </tr>
                </tbody>
              </table>
              <p><b>Only a sample of our {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is available with a free trial. Full access is given on subscription.</b></p>
            </section>
          </div>

          <div v-else class="box-body content">
            <p v-if="$store.state.user && locale === 'en_US'">
              The Spelling Shed spelling {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme of work' }} gives full coverage of the curriculum for grades K-5 but lists from any Stage can be used with any grade level. Each stage relates to its corresponding year so Stage 1 is for Kindergarten, Stage 2 is for 1st grade etc. The {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is broken down into weekly objectives, each of which has a word list, a learning activity and a practice sheet. All of the spelling lists are available in our system for use within the Spelling Shed web and mobile games.
            </p>
            <p v-else>
              The Spelling Shed spelling {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} of work gives full coverage of the curriculum for Years 1-6. Each stage relates to its corresponding year so stage 1 is for year 1. The {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is broken down into weekly objectives, each of which has a word list, a learning activity and a practice sheet. All of the spelling lists are available in our system for use within the Spelling Shed web and mobile games.
            </p>
            <p>Use of our {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} is subject to our terms of use <a href="https://www.edshed.com/terms">HERE</a>. You may download and print unlimited copies and use within the classroom or to give to pupils as homework while you have an active subscription. All copies MUST be deleted / destroyed if your subscription ceases. You may not share our {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} outside of your institution or distribute any of our {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} documents in any way, including posting on e.g. school websites or social media platforms.</p>
            <router-link to="/lists" tag="button" class="button is-link">
              View spelling lists
            </router-link>
            <p>Our {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} PowerPoints use the font "OpenDyslexicAlta". Download the font for free <a href="https://www.opendyslexic.org/">HERE</a>.</p>
            <section class="section">
              <table class="table is-striped is-bordered">
                <thead>
                  <tr>
                    <th>Stage</th><th>Print PDF</th><th>Powerpoint</th>
                  </tr>
                </thead>
                <tbody v-if="locale === 'en_US'">
                  <tr>
                    <td><b>Challenge Activities</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Challenge-Weeks-Pack.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Challenge Activities</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Challenge-Weeks-Pack.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Challenge Activities</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 1</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-1.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 1</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-1.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 1</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 2</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-2.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 2</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-2.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 2</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 3</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-3.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 3</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-3.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 3</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 4</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-4.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 4</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-4.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 4</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 5</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-5.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 5</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling-Shed-Curriculum-Stage-5.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 5</a></td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td><b>Challenge Activities</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Challenge Weeks Pack.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Challenge Activities</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Challenge Weeks Pack.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Challenge Activities</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 1</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 1.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 1</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 1.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 1</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 2</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 2.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 2</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 2.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 2</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 3</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 3.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 3</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 3.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 3</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 4</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 4.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 4</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 4.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 4</a></td>
                  </tr>
                  <tr>
                    <td><b>Stage 5</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 5.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 5</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 5.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 5</a></td>
                  </tr>
                  <tr v-if="$store.state.user.school.locale != 'en_US'">
                    <td><b>Stage 6</b></td><td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 6.pdf'" target="_blank"><i class="fas fa-file-pdf has-text-danger" /> Stage 6</a></td>
                    <td><a :href="'https://files.edshed.com/scheme/' + locale + '/Spelling Shed Scheme Stage 6.pptx'" target="_blank"><i class="fas fa-file-powerpoint has-text-danger" /> Stage 6</a></td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
          <p class="small">
            This {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme of work' }} is copyright &copy; Education Shed Ltd and is for use solely by subscribing schools. Any element of this {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} may not be distributed for any purposes and any person doing so may have their account suspended and legal action taken against them.
          </p>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SchemeView',
  computed: {
    isTrial () {
      if (!this.$store.state.user) {
        return true
      }
      if (!this.$store.state.user.school) {
        return true
      }
      if (!this.$store.state.user.school.teacher) {
        return true
      }
      if (!this.$store.getters.hasSubscription) {
        return true
      }
      if (!this.$store.getters.hasSpelling) {
        return true
      }
      return this.$store.getters.isSpellingTrial
    },
    locale () {
      if (this.$store.state.user) {
        if (this.$store.state.user.school) {
          return this.$store.state.user.school.locale
        }
        return this.$store.state.user.locale
      }
      return navigator.language.replace('-', '_')
    }
  },
  mounted () {
    this.$nextTick(() => {
      // if (!this.$store.getters.hasSpelling) {
      //   this.$router.push({ path: '/' })
      // }
    })
  }
}
</script>
<style scoped>
  .table>thead:first-child>tr:first-child>th, .table>thead>tr>th {
    border-top:1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }
</style>
